
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
/* eslint-disable @next/next/inline-script-id */
import '../../styles/main.scss'

import { useEffect } from 'react'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import pt from 'javascript-time-ago/locale/pt'
import _ from 'lodash'
import { Settings } from 'luxon'
import Script from 'next/script'
import { SessionProvider } from 'next-auth/react'
import { DefaultSeo } from 'next-seo'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { ToastContainer } from 'react-toastify'
import store from 'store2'

import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import {
  BRAND_NAME,
  BRAND_SHORT_NAME,
  RELEASE_YEAR,
  SITE_DESCRIPTION,
  SITE_URL,
  TWITTER_NAME
} from '../../react-admin.config'

import { AppProvider } from '@/domain/App/context'
import AuthGuard from '@/domain/AuthGuard'
import { api } from '@/services/api'

Settings.defaultZone = 'America/Sao_Paulo'
Settings.defaultLocale = 'pt-BR'

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await api.get(queryKey[0])
  return data
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      refetchIntervalInBackground: 60000,
      retry: true,
      refetchOnMount: true,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      queryFn: defaultQueryFn
    }
  }
})

const App = ({ Component, pageProps: { session, ...pageProps } }) => {
  useEffect(() => {
    // Google Global tag ads
    const handleGoogleTagManager = () => {
      window.dataLayer = window.dataLayer || []
      const gtag = () => {
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'AW-11003562336')
    }

    // Facebook global tag ads
    const handleFacebookPixel = () => {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
      fbq('init', '3217741848283820')
      fbq('track', 'PageView')
    }

    handleFacebookPixel()
    handleGoogleTagManager()
  }, [])

  useEffect(() => {
    const body = document.querySelector('body')

    document.documentElement.setAttribute('data-bs-theme', 'light')

    if (body) {
      body.id = 'ttz_app_body'
      body.setAttribute('data-ttz-app-layout', 'dark-sidebar')
      body.setAttribute('data-ttz-app-header-fixed', 'true')
      body.setAttribute('data-ttz-app-sidebar-enabled', 'true')
      body.setAttribute('data-ttz-app-sidebar-fixed', 'true')
      body.setAttribute('data-ttz-app-sidebar-hoverable', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-header', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-toolbar', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-footer', 'true')
      body.setAttribute('data-ttz-app-toolbar-enabled', 'true')
    }
  }, [])

  useEffect(() => {
    // Google Tag Manager
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })

    // React Time Ago
    // if (store('NEXT_LOCALE') == 'pt') {
    //   TimeAgo.setDefaultLocale(pt)
    //   TimeAgo.addLocale(pt)
    // }
    // if (store('NEXT_LOCALE') == 'en') {
    //   TimeAgo.setDefaultLocale(en)
    //   TimeAgo.addLocale(en)
    // }

    if (store('NEXT_LOCALE') == 'en') {
      Settings.defaultLocale = 'en-US'
    }

    // Resize Observer Polyfill
    if (typeof window !== 'undefined' && !window.ResizeObserver) install()
  }, [])

  useEffect(() => TimeAgo.addDefaultLocale(pt), [])

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - ${BRAND_NAME}`}
        defaultTitle={BRAND_NAME}
        description={SITE_DESCRIPTION}
        lang="pt_BR"
        openGraph={{
          type: 'website',
          url: SITE_URL,
          title: BRAND_NAME,
          description: SITE_DESCRIPTION,
          locale: 'pt_BR',
          site_name: BRAND_NAME
        }}
        facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
        twitter={{
          handle: TWITTER_NAME,
          site: TWITTER_NAME,
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/apple-touch-icon.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/favicon-16x16.png'
          },
          { rel: 'manifest', href: '/site.webmanifest' }
        ]}
        additionalMetaTags={[
          {
            name: 'msapplication-TileColor',
            content: '#ffffff'
          },
          {
            name: 'theme-color',
            content: '#ffffff'
          }
        ]}
      />
      <Script
        async
        src="https://cdn-public-library.clicksign.com/embedded/embedded.min-2.0.0.js"
        type="text/javascript"
      />
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-11003562336"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-11003562336');
        `}
      </Script>

      <Script strategy="afterInteractive">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '3217741848283820');
        fbq('track', 'PageView');
        `}
      </Script>

      <Script strategy="afterInteractive">
        {`
          (function(){var w=window;var ic=w.Intercom;
          if(typeof ic==="function"){ic('reattach_activator');
          ic('update',w.intercomSettings);}else{var d=document;
          var i=function(){i.c(arguments);};i.q=[];
          i.c=function(args){i.q.push(args);};w.Intercom=i;
          var l=function(){var s=d.createElement('script');s.type='text/javascript';
          s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';
          var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
          if(document.readyState==='complete'){l();}
          else if(w.attachEvent){w.attachEvent('onload',l);}
          else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <SessionProvider
            staleTime={5 * 60}
            clientMaxAge={5 * 60}
            refetchInterval={0}
            session={session}
          >
            <CookiesProvider>
              <AuthGuard isPublic={Component.isPublic}>
                <AppProvider>
                  <Component {...pageProps} />
                </AppProvider>
              </AuthGuard>
            </CookiesProvider>
          </SessionProvider>
        </Hydrate>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

const __Page_Next_Translate__ = App


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  