import _ from 'lodash'

import { defineAbility } from '@casl/ability'

import { Comment, User } from './entities'

const useAbility = user =>
  defineAbility(can => {
    if (!user) return
    const roles = _.get(user, 'roles', [user.main_role])

    can('manage', 'User', { id: user.id })
    can('manage', 'Comment', { user_id: user.id })

    roles.forEach(role => {
      // const roleName = _.get(role, 'name')
      // const resourceType = _.get(role, 'resource_type')
      // if (systemAdmin || admin) {
      //   if (systemAdmin) {
      //     can('manage', 'System')
      //     can('manage', 'all')
      //   }
      //   can('manage', 'User')
      //   can('manage', 'Comment')
      // }
    })
  })

export default useAbility
export { User, Comment }
