const useAsideMenuItems = ({ user, t }) => {
  return [
    {
      slug: 'dashboard',
      title: t('asideMenu.dashboard'),
      icon: 'fad fa-user-tie',
      href: '/'
    },
    {
      slug: 'my-digital-products',
      title: t('asideMenu.myDigitalProducts'),
      icon: 'fad fa-box-open',
      href: '/my-digital-products'
    },
    {
      slug: 'orders',
      title: t('asideMenu.orders'),
      icon: 'fad fa-bag-shopping',
      href: '/orders'
    },
    {
      slug: 'contracts',
      title: t('asideMenu.contracts'),
      icon: 'fad fa-file-signature',
      href: '/contracts'
    },
    {
      slug: 'financial',
      title: t('asideMenu.financial'),
      icon: 'fad fa-money-check-dollar',
      href: '/financial'
    },
    {
      slug: 'settings',
      title: t('asideMenu.profile'),
      icon: 'fad fa-user-tie',
      href: '/profile-settings'
    }
    // {
    //   slug: 'support',
    //   title: t('asideMenu.help'),
    //   icon: 'fad fa-user-tie',
    //   href: '/support-center'
    // }
  ]
}

export default useAsideMenuItems
