import axios from 'axios'
import _ from 'lodash'
import { signOut, getSession } from 'next-auth/react'
import qs from 'qs'

import { getErrorMessage, showErrorAlert, getQuery } from '@tootz/react-admin'

axios.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'brackets' })

const api = axios.create({
  // withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL,
  headers: {
    accept: 'application/json'
    // Authorization:
    //   '57f2cea74d55c393f4e062d8f9befca12cd1012fa0ff9c04c9d84eae64c6bdae6bab2baceae7d26aaf62911fa0abec39f6fe90f16d9c584a53da8a2b83eb3fe0'
  },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

api.interceptors.request.use(async config => {
  const session = await getSession()

  if (session?.accessToken !== undefined)
    config.headers.Authorization = `Bearer ${session.accessToken}`
  return config
})

api.interceptors.response.use(
  response => response,
  async error => {
    if (401 === _.get(error, 'response.status')) {
      // return signOut()
    } else return Promise.reject(error)
  }
)

// Self API
const selfApi = axios.create({
  baseURL: '/api',
  headers: { accept: 'application/json' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export { api, selfApi, getErrorMessage, showErrorAlert, getQuery }
