export class User {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'User'
  }
}

export class Comment {
  constructor(attrs) {
    Object.assign(this, attrs)
  }

  static get modelName() {
    return 'Comment'
  }
}
