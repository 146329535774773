import _ from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { useSessionContext } from '@tootz/react-admin'
import { Symbol } from '@tootz/react-admin/components'


const AsideUser = () => {
  const { t } = useTranslation()
  const { user } = useSessionContext()

  return (
    <div className="d-flex align-items-center">
      <Symbol
        imageUrl={_.get(user, 'avatar.file.url', '/placeholders/user.jpg')}
        circle
      />
      <div className="ps-3">
        <span className="text-white fw-bold d-block fs-8">{user.name}</span>
        <Link
          href="/auth/sign-out"
          className="fs-8 text-gray-500 text-hover-gray-200"
        >
          {t('buttons.signOut')}
        </Link>
      </div>
    </div>
  )
}

export default AsideUser
