import React, { useEffect } from 'react'

import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import ReactLoading from 'react-loading'

import { useSession } from '@tootz/react-admin'

import { selfApi } from '@/services/api'

import MyApp from './App'

const GuardContainer = ({ isPublic, children }) => {
  const router = useRouter()
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  useEffect(() => {
    if (typeof window !== 'undefined' && status === 'success') {
      let userData = {}

      if (session) {
        selfApi
          .get('/get-user-hash')
          .then(response => {
            userData = {
              name: session?.user?.name,
              email: session?.user?.email,
              user_hash: response?.data?.hash
            }
          })
          .catch(error => {
            console.error(error)
          })
      }

      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        ...userData
      })
    }
  }, [status])

  const redirectToSignIn = () => {
    signIn()
  }

  if (isPublic) return <MyApp selfLayout="blank">{children}</MyApp>

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
        <ReactLoading type="bubbles" color="#3F4254" height={50} width={50} />
      </div>
    )

  if (router.pathname.startsWith('/p/')) return <>{children}</>
  if (router.pathname.startsWith('/auth/')) return <>{children}</>

  if (!loading && !session) return <>{redirectToSignIn()}</>

  return <MyApp>{children}</MyApp>
}

export default GuardContainer
