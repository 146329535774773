import { useEffect } from 'react'

import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import setLanguage from 'next-translate/setLanguage'
import useTranslation from 'next-translate/useTranslation'
import store from 'store2'

import { Admin, useDataProvider, useSession } from '@tootz/react-admin'

import {
  BRAND_NAME,
  BRAND_SHORT_NAME,
  RELEASE_YEAR,
  SITE_DESCRIPTION,
  SITE_URL,
  TWITTER_NAME
} from '../../../react-admin.config'

import useAbility from '@/services/ability'
import { api } from '@/services/api'

import AsideUser from './Aside/User'
import getAsideMenuItems from './getAsideMenuItems'
import getNavbarMenuItems from './getNavbarMenuItems'
import getTopbarItems from './getTopbarItems'

const brand = {
  name: BRAND_NAME,
  shortName: BRAND_SHORT_NAME,
  siteUrl: SITE_URL
}

const logo = {
  default: '/logo.png',
  light: '/logo.png',
  dark: '/logo-dark.png',
  icon: {
    default: '/logo.png',
    light: '/icon-light.png',
    dark: '/icon-dark.png'
  }
}

const footer = { disabled: true }

const asideFooter = (
  <div className="ps-8 pb-8 pe-4">
    <AsideUser />
  </div>
)

const MyAdmin = ({ selfLayout, children }) => {
  const { t, lang } = useTranslation()
  const { data: session } = useSession()
  const dataProvider = useDataProvider('', api)
  const fetcher = (resource, init) =>
    api.get(resource, init).then(res => res.data)
  const asideMenuItems = getAsideMenuItems({ user: session?.user, t })
  const navbarMenuItems = getNavbarMenuItems(session?.user)
  const topbarItems = getTopbarItems(session?.user)
  const ability = useAbility(session?.user)
  const { pathname } = useRouter()

  useEffect(() => {
    if (pathname.includes('checkout')) return

    if (session && session?.user?.language && session?.user?.language != lang) {
      setLanguage(session?.user?.language)
    }
  }, [])

  return (
    <>
      <DefaultSeo
        titleTemplate={`${BRAND_NAME} | %s`}
        defaultTitle={BRAND_NAME}
        description={SITE_DESCRIPTION}
        openGraph={{
          type: 'website',
          url: SITE_URL,
          title: BRAND_NAME,
          description: SITE_DESCRIPTION,
          locale: 'pt_BR',
          site_name: BRAND_NAME
        }}
        facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
        twitter={{
          handle: TWITTER_NAME,
          site: TWITTER_NAME,
          cardType: 'summary_large_image'
        }}
      />
      <Admin
        ability={ability}
        httpClient={api}
        dataProvider={dataProvider}
        fetcher={fetcher}
        session={session}
        logo={logo}
        brand={brand}
        header={{
          topbarItems: topbarItems
        }}
        sidebar={{
          theme: 'dark',
          menuItems: asideMenuItems,
          footer: session ? asideFooter : <></>,
          scrollOffset: '35px'
        }}
        footer={footer}
        selfLayout={selfLayout}
      >
        {children}
      </Admin>
    </>
  )
}

export default MyAdmin
