import { createContext, useContext, useState } from 'react'

const AppContext = createContext()

const useAppContext = () => useContext(AppContext)

const AppProvider = ({ children, ...rest }) => {
  const [_showModal, _setShowModal] = useState(false)
  const showModal = _setShowModal

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show={_showModal}
        onHide={() => {
          onHide && onHide()
          _setShowModal(false)
        }}
        onSave={record => {
          onSave && onSave(record)
          _setShowModal(false)
        }}
      />
    )
  }

  return (
    <AppContext.Provider
      value={{
        showModal,
        ...rest
      }}
    >
      {children}
      {_showModal && <Modal {..._showModal} />}
    </AppContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withApp = Component => props => {
  return (
    <AppProvider>
      <Component {...props} />
    </AppProvider>
  )
}

export { useAppContext, AppProvider, withApp }
